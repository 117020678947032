import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'colorDeliveryStatus'
})
export class ColorDeliveryStatusPipe implements PipeTransform {

    transform(status: string): string {
        let ionicColor: string;

        switch (status) {
            case 'DELIVER':
                ionicColor = 'success';
                break;

            case 'DELIVER_ABNORMAL':
                ionicColor = 'warning';
                break;

            case 'WAITING_RECEPTION':
                ionicColor = 'secondary';
                break;

            case 'WAITING_PICKUP':
                ionicColor = 'medium';
                break;

            case 'IN_PROGRESS':
                ionicColor = 'primary';
                break;

            case 'IN_TRANSIT':
                ionicColor = 'primary';
                break;

            default:
                ionicColor = 'dark';
                break;
        }

        return ionicColor;
    }

}
