import {Pipe, PipeTransform} from '@angular/core';
import {UserDto} from "../gateways/dtos/userDto";

@Pipe({
    name: 'getUser'
})
export class GetUserPipe implements PipeTransform {

    transform(userId: number, allUsers: Array<UserDto>) {
        const finduser = allUsers.findIndex(x => x.id == userId);
        if (finduser != -1) {
            return allUsers[finduser].firstName + ' ' + allUsers[finduser].lastName;
        } else {
            return null;
        }
    }
}
