import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'translateDeliveryStatus'
})
export class TranslateDeliveryStatusPipe implements PipeTransform {

    transform(status: string): string {
        let traduction: string;

        switch (status) {
            case 'DELIVER':
                traduction = 'livré & réceptionné';
                break;

            case 'DELIVER_ABNORMAL':
                traduction = 'terminé avec anomalie';
                break;

            case 'WAITING_RECEPTION':
                traduction = 'en attente de réception';
                break;

            case 'WAITING_PICKUP':
                traduction = 'en attente de ramassage';
                break;

            case 'IN_PROGRESS':
                traduction = 'en cours de livraison';
                break;

            case 'IN_TRANSIT':
                traduction = 'en transit';
                break;

            default:
                traduction = 'aucun status défini';
                break;
        }

        return traduction;
    }

}
