import {Pipe, PipeTransform} from '@angular/core';
import {UserDto} from "../gateways/dtos/userDto";

@Pipe({
    name: 'getUserByLogin'
})
export class GetUserByLoginPipe implements PipeTransform {

    transform(userLogin: string, allUsers: Array<UserDto>) {
        const finduser = allUsers.findIndex(x => x.login == userLogin);
        if (finduser != -1) {
            return allUsers[finduser].firstName + ' ' + allUsers[finduser].lastName;
        } else {
            return null;
        }
    }

}
