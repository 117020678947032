import {Pipe, PipeTransform} from '@angular/core';
import {DeliveryDto} from "../gateways/dtos/delivery-dto";
import * as moment from 'moment';

@Pipe({
    name: 'deliverDuration'
})
export class DeliverDurationPipe implements PipeTransform {

    transform(delivery: DeliveryDto): unknown {
        let duration: number = 0;
        if (delivery.receivedAt && delivery.pickupAt) {
            duration = new Date(delivery.receivedAt).getTime() - new Date(delivery.pickupAt).getTime();
        } else if (delivery.pickupAt && !delivery.receivedAt) {
            duration = new Date().getTime() - new Date(delivery.pickupAt).getTime();
        }
        // affichage au format hh:mm avec moment.js
        return moment.utc(duration).format('HH:mm');
    }

}
