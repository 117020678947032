// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,

	// back_url: 'https://dev-biolbs.sangdetour.fr/api/',
	back_url: 'https://stg-biolbs.sangdetour.fr/api/',

	// version: '1.1.11',
	// versionDate: '10 avril 2024'

	version: '2.0.21 test',
	versionDate: '03 mars 2025'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
