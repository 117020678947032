import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'iconDeliveryStatus'
})
export class IconDeliveryStatusPipe implements PipeTransform {

    transform(status: string): string {
        let icon: string;

        switch (status) {
            case 'DELIVER':
                icon = 'checkmark-circle-outline';
                break;

            case 'DELIVER_ABNORMAL':
                icon = 'help-outline';
                break;

            case 'WAITING_RECEPTION':
                icon = 'checkmark-outline';
                break;

            case 'WAITING_PICKUP':
                icon = 'share-outline';
                break;

            case 'WAITING_CONNECTION':
                icon = 'share-outline';
                break;

            case 'IN_PROGRESS':
                icon = 'car';
                break;

            case 'IN_TRANSIT':
                icon = 'car';
                break;

            default:
                icon = 'square-outline';
                break;
        }

        return icon;
    }

}
